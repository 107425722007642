* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  background-color: #090e2e;
  color: white;
  padding: 20px
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  background: none;
  background-color: black;
  border: 2px solid white;
  border-radius: 10px;
  padding: 10px;
  font-size: 1.2em;
  color: white;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type=number] {
  -moz-appearance: textfield;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.tile {
  background-color: black;
  border: 2px solid #606060;
  border-radius: 10px;
  padding: 10px;
}

.tile.past {
  color: darkgrey;
}

.tile.current {
  font-style: bold;
  border-color: #2196F3;
  background-color: #042947;
}

.tile.future {
  color: darkgrey;
}